import React, { useState } from "react";
import { useSelector } from "react-redux";
// import Link from "next/link";
import { useRouter } from "next/router";
import { signOut, useSession } from "next-auth/react";
import { slide as Menu } from "react-burger-menu";
import { FormattedMessage } from "react-intl";
import NormalPopup from "../modal/normalPopup";
// import ClevertapReact from "utils/clevertapHelper";
import { handleLogout as reduxLogout } from "redux/actions/authActions";
import { decryptData } from "utils/encryption";
import { getCookie } from "utils/cookieHelper";

const MainMenuDesktop = () => {
  const router = useRouter();
  const [isOpen, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { data: session } = useSession();
  // const dispatch = useDispatch();
  const { userData } = useSelector(({ UserReducer }) => UserReducer);
  const { userLocalDetail , encryptedToken } = useSelector(({ AuthReducer }) => AuthReducer);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };
  // const handleLogout = () => {
    
  //   fetch("/api/clearToken", {
  //     method: "post",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ encryptedToken: "" }),
  //   }).then((res) => {
  //     if (res.status === 200) {
  //       signOut({ callbackUrl: `${window.location.origin}` });
  //       reduxLogout();
  //       localStorage.removeItem("lallantopSession2");
  //       localStorage.removeItem("imageData");
  //       // localStorage.removeItem("lallantopSession2");
  //       //setCookie("lallantop", "", 0, "1970-01-01T00:00:00.000Z");
  //     }
  //   });
  // };

  const handleLogout = () => {
    try {
    const cookie  = JSON.parse(decodeURIComponent(getCookie('lallantop')))
    const token = decryptData(cookie?.encryptedToken) 
    fetch("/api/clearToken", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ encryptedToken: "" ,token }),
    }).then(async (res) => {
      const data = await res.json()
      // if (res.status === 200) {
      if (data.success) {
        reduxLogout();
        localStorage.removeItem("lallantopSession2");
        localStorage.removeItem("imageData");
        signOut({ callbackUrl: `${window.location.origin}` });
        //setCookie("lallantop", "", 0, "1970-01-01T00:00:00.000Z");
      }
    });
  }
    catch (error) {
      console.log(error , '  error in handle logout');
      
      return
    }
  };


  // Will be used for oneTap login

  // const responseGoogle = useCallback(() => {
  //   console.log("use callback");
  //   GenerateUniqId().then((deviceId) => {
  //     const cleverTapId = localStorage
  //       .getItem("WZRK_G")
  //       .toString()
  //       .replace("%22", "")
  //       .replace("%22", "");

  //     const deviceToken = localStorage.getItem("deviceToken");

  //     let loginObj = {
  //       loginType: GOOGLE_LOGIN,
  //       deviceType: "web",
  //       deviceId,
  //       deviceToken,
  //       facebookId: process.env.NEXT_PUBLIC_FACEBOOK_ID,
  //       googleId: session?.user?.uniqueId,
  //       appleId: "",
  //       fullname: session?.user?.name,
  //       email: session?.user?.email,
  //       cleverTapId,
  //     };
  //     dispatch(actions.LoggedIn(loginObj));
  //   });
  // }, [
  //   dispatch,
  //   session?.user?.email,
  //   session?.user?.uniqueId,
  //   session?.user?.name,
  // ]);

  return (
    <Menu
      width={360}
      className={isOpen ? "my-menu" : "my-menu menu-hide-full"}
      burgerButtonClassName={isOpen ? "open" : "burger-btn"}
      isOpen={isOpen}
      onOpen={handleIsOpen}
      onClose={handleIsOpen}
      overlayClassName={"menu-overlay"}
      bodyClassName={"bm-menu-open"}
      // disableOverlayClick
    >
      <div className="menu-head">
        <div className="close-button">
          {/* <i className="icon-close"></i> */}
        </div>

        <div className="profile-box">
          {userLocalDetail?.id || session?.user?.email ? (
            <a href="/loading/profile" passHref>
              <a>
                <div className="pic-wrap">
                  <div className="pic-thumb">
                    <img
                      src={
                        userData?.profileImage ||
                        userLocalDetail?.image ||
                        session?.user?.image ||
                        "/assets/images/user-circle.svg"
                      }
                      referrerPolicy="no-referrer"
                      alt="icon"
                    />
                  </div>
                </div>
              </a>
            </a>
          ) : null}
          {userLocalDetail?.id || session?.user?.email ? null : (
            <div className="user-pic">
              <img
                src={
                  "https://static.thelallantop.com/images/post/1707897306558_user-circle_(1)[1].webp?width=80&height=80"
                }
                width="80"
                height="80"
                alt="user"
                loading="lazy"
              />
            </div>
          )}
          <div className="pro-text">
            {userLocalDetail?.id || session?.user?.email ? null : (
              <div className="m-signin">
                <a
                  // href={{
                  //   pathname: "/api/auth/signin",
                  //   query: { returnUrl: router.pathname },
                  // }}
                  href={`/api/auth/signin?returnUrl=${router.pathname}`}
                  passHref
                >
                  <a onClick={closeSideBar}>
                    <div style={{ color: "white" }}>
                      <FormattedMessage
                        id="signin"
                        defaultMessage="साइन इन करें"
                      />
                    </div>
                  </a>
                </a>
              </div>
            )}
            <div className="user-name">
              {userData?.fullname ||
                userLocalDetail?.name ||
                session?.user?.name ||
                ""}
            </div>
            <div className="user-contact">
              {userData?.email ||
                userLocalDetail?.email ||
                session?.user?.email ||
                ""}
            </div>
          </div>
        </div>
        <div className="head-top">
          {/* <div className="menu-head-box">
              {/* <a href="/hashtags" passHref> */}
          {/* <a href="/hashtags" style={{width:"90px"}} onClick={closeSideBar}>
                  <img
                    src={
                      "https://static.thelallantop.com/images/post/1695113335086_group_313.webp?width=60"
                    }
                    alt="Lallantop"
                    loading="lazy"
                    className="menu-head-icon"
                  />
                  <div className="menu-head-text">
                    <FormattedMessage id="hashtag" defaultMessage="हैश्टैग" />
                  </div>
                </a> */}
          {/* </a> */}
          {/* </div> }
          {/* <div className="menu-head-box">
            <a
              onClick={() => {
                closeSideBar();
                setModalOpen(true);
              }}
            >
              <div className="menu-head-icon">
                <span className="icon-notification"></span>
              </div>
              <div className="menu-head-text">
                <FormattedMessage
                  id="notification"
                  defaultMessage="नोटिफिकेशन्स"
                />
              </div>
            </a>
          </div> */}
          <div className="menu-head-box">
            {/* <a href="/bookmarks" passHref> */}
            <a
              href="/bookmarks"
              style={{ width: "90px" }}
              onClick={closeSideBar}
            >
              <img
                src={
                  "https://static.thelallantop.com/images/post/1695113608333_group_312.webp?width=60"
                }
                alt="Lallantop"
                loading="lazy"
                className="menu-head-icon"
              />
              <div className="menu-head-text">
                <FormattedMessage id="bookmarks" defaultMessage="बुक्मार्क्स" />
              </div>
            </a>
            {/* </a> */}
          </div>
          <div className="menu-head-box">
            {/* <a href="/text" passHref> */}
            <a href="/text" style={{ width: "65px" }} onClick={closeSideBar}>
              <img
                src={"/assets/images/text-white.png"}
                alt="Lallantop"
                loading="lazy"
                width="20px"
                height="20px"
                className="menu-head-icon"
              />
              <div className="menu-head-text">
                <FormattedMessage id="readE" defaultMessage="हैश्टैग" />
              </div>
            </a>
            {/* </a> */}
          </div>
          <div className="menu-head-box">
            {/* <a href="/video" passHref> */}
            <a href="/video" style={{ width: "70px" }} onClick={closeSideBar}>
              <img
                src={"/assets/images/video-white.png"}
                alt="Lallantop"
                loading="lazy"
                className="menu-head-icon"
              />
              <div className="menu-head-text">
                <FormattedMessage id="video" defaultMessage="हैश्टैग" />
              </div>
            </a>
            {/* </a> */}
          </div>
        </div>
      </div>
      <div className="menu-body">
        <ul>
          {/* <li>
            <a href="/quiz" passHref>
              <a onClick={closeSideBar}>
                <i className="icon-quiz"></i>{" "}
                <span>
                  <FormattedMessage id="quiz" defaultMessage="क्विज़" />
                </span>
              </a>
            </a>
          </li> */}
          {/* <li>
            <a href="/quiz" onClick={closeSideBar}>
              <img
                src={
                  "https://static.thelallantop.com/images/post/1694177399046_group_313.webp?width=60"
                }
                loading="lazy"
                alt="quiz icon"
              />
              <span>
                <FormattedMessage
                  id="quiz_khele"
                  defaultMessage="क्विज़ खेलें"
                />
              </span>
            </a>
          </li> */}
          <li>
            {/* <a href="/visualstories" passHref> */}
            <a href="/visualstories" onClick={() => closeSideBar()}>
              <img
                src={
                  "https://static.thelallantop.com/images/post/1694177098246_group_313.webp?width=60"
                }
                loading="lazy"
                alt="Visual Story icon"
              />
              <span>
                <FormattedMessage
                  id="stories"
                  defaultMessage="विज़ुअल स्टोरीज़"
                />
              </span>
            </a>
            {/* </a> */}
          </li>
          <li>
            {/* <a href="/topic" passHref> */}
            <a href="/topic" onClick={() => closeSideBar()}>
              <i className="icon-play-circle custom-topic-icon"></i>
              <span>
                <FormattedMessage id="topics" defaultMessage="टॉपिक्स" />
              </span>
            </a>
            {/* </a> */}
          </li>
          {/* <li>
          
            <a
              href="/polls"
              onClick={() => {
               
                closeSideBar();
              }}
            >
              <img
                src={
                  "https://static.thelallantop.com/images/post/1694177324013_group_312.webp?width=60"
                }
                loading="lazy"
                alt="Polls icon"
              />{" "}
              <span>
                <FormattedMessage id="polls" defaultMessage="पोल्स" />
              </span>
            </a>
          </li> */}
          <li>
            {/* <a href="/team-lallantop/editorial-team" passHref> */}
            <a href="/team-lallantop/editorial-team" onClick={closeSideBar}>
              <img
                src={
                  "https://static.thelallantop.com/images/post/1693554312990_group.webp?width=60"
                }
                loading="lazy"
                alt="our-team-icon"
              />
              <span>
                <FormattedMessage id="menu_team" defaultMessage="हमारी टीम" />
              </span>
            </a>
            {/* </a> */}
          </li>

          <li>
            {/* <a href="/settings" passHref> */}
            <a href="/settings" onClick={closeSideBar}>
              <img
                src={
                  "https://static.thelallantop.com/images/post/1693554253186_group.webp?width=60"
                }
                loading="lazy"
                alt="settings-icon"
              />
              <span>
                <FormattedMessage id="settings" defaultMessage="सेटिंग्स" />
              </span>
            </a>
            {/* </a> */}
          </li>
          <li>
            {/* <a href="/feedback" passHref> */}
            <a href="/feedback" onClick={closeSideBar}>
              <img
                src={
                  "https://static.thelallantop.com/images/post/1694177476065_group_312.webp?width=60"
                }
                loading="lazy"
                alt="quiz icon"
              />
              <span>
                <FormattedMessage id="feedback" defaultMessage="फ़ीड्बैक" />
              </span>
            </a>
            {/* </a> */}
          </li>
          {(userLocalDetail?.id || session?.user?.email) && (
            <li>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleLogout();
                  closeSideBar();
                }}
              >
                <img
                  src={
                    "https://static.thelallantop.com/images/post/1693553806489_group_312.webp?width=60"
                  }
                  loading="lazy"
                  alt="settings-icon"
                />
                <span>
                  <FormattedMessage id="logout" defaultMessage="लॉग आउट" />
                </span>
              </a>
            </li>
          )}
        </ul>
      </div>
      <div className="menu-foot">
        <ul>
          {/* <li>
            <a href="/loading/authors" passHref>
              <a onClick={closeSideBar}>
                <span>
                  <FormattedMessage
                    id="menu_author"
                    defaultMessage="हमारे लेखक"
                  />
                </span>
                <i className="icon-arrow-right menu-right-icon"></i>
              </a>
            </a>
          </li> */}
          {/* <li>
            <a href="/team-lallantop/editorial-team" onClick={() => closeSideBar()} passHref>
                <span>
                  <FormattedMessage id="menu_team" defaultMessage="हमारी टीम" />
                </span>
                <i className="icon-arrow-right menu-right-icon"></i>
            </a>
          </li> */}
          {/* <li>
            <a href="/settings" passHref>
              <a onClick={closeSideBar}>
                <span>हमारे ऑथर</span>
                <i className="icon-arrow-right"></i>
              </a>
            </a>
          </li> */}
          {/* <li>
            <a href="/preferences" passHref>
              <a onClick={closeSideBar}>
                <span>
                  <FormattedMessage
                    id="apni_pasand_chuniye"
                    defaultMessage="अपनी पसंद चुनिए"
                  />
                </span>
                <i className="icon-arrow-right"></i>
              </a>
            </a>
          </li> */}
          {/* <li>
            <a href="/quiz" onClick={closeSideBar}>
              <span>
                <FormattedMessage
                  id="quiz_khele"
                  defaultMessage="क्विज़ खेलें"
                />
              </span>
              <i className="icon-arrow-right menu-right-icon"></i>
            </a>
          </li>
          <li>
            <a href="/settings" onClick={closeSideBar} passHref>
                <span>
                  <FormattedMessage id="settings" defaultMessage="सेटिंग्स" />
                </span>
                <i className="icon-arrow-right menu-right-icon"></i>
            </a>
          </li>
          <li>
            <a href="/feedback" onClick={closeSideBar} passHref>
                <span>
                  <FormattedMessage id="feedback" defaultMessage="फ़ीड्बैक" />
                </span>
                <i className="icon-arrow-right menu-right-icon"></i>
            </a>
          </li> */}
          {(userLocalDetail?.id || session?.user?.email) && (
            <li className="menu-logout">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleLogout();
                  closeSideBar();
                }}
              >
                <span>
                  <FormattedMessage id="logout" defaultMessage="लॉग आउट" />
                </span>
                <i className="icon-arrow-right"></i>
              </div>
            </li>
          )}
        </ul>
      </div>
      <NormalPopup showModal={modalOpen} onClose={() => setModalOpen(false)} />
    </Menu>
  );
};
export default MainMenuDesktop;
